












































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, onBeforeUnmount, watch } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { usePodocoreModule, usePodocoreModuleService } from '@/plugins/podocore';
import { useDataTable } from '@/utils/data-table.utils';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import CompositeCard from '../composite/CompositeCard.vue';
import AlertError from '@/components/alerts/AlertError.vue';
// Import types ------------------------------------------------------------------------------------
import type { DataTableHeader } from 'vuetify';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'EquipmentDevices',
  components: { CompositeCard, AlertError },
  setup(_, { root }) {
    const authenticatedRequest = usePodocoreModule('request').useAuthenticatedRequest(
      `${apiConfig.default}/devices`
    );

    const workspacesService = usePodocoreModuleService('workspaces');

    const { options, updateOptions, sanitizedData, hasBeenLoadedAtLeastOnce, request } = useDataTable(
      authenticatedRequest,
      {
        itemsPerPage: 6,
        sortBy: ['left.footSize'],
        sortDesc: [true]
      }
    );

    const { error, isPending, cancel } = authenticatedRequest;

    watch(workspacesService.state, (currentState) => {
      if (currentState.matches('success')) {
        request();
      }
    });

    onBeforeUnmount(() => {
      cancel();
    });

    const headers = computed((): DataTableHeader[] => [
      {
        text: String(root.$tc('commons.standards.type')),
        value: 'deviceType'
      },
      {
        text: String(root.$t('commons.standards.code')),
        value: 'abbr'
      },
      {
        text: String(root.$t('commons.standards.foot-size')),
        value: 'infos.left.footSize'
      },
      {
        text: `MAC (${String(root.$t('commons.standards.left')).slice(0, 1)})`,
        value: 'infos.left.mac'
      },
      {
        text: `MAC (${String(root.$t('commons.standards.right')).slice(0, 1)})`,
        value: 'infos.right.mac'
      }
    ]);

    function refresh() {
      request();
    }

    return {
      headers,
      sanitizedData,
      error,
      options,
      // Methods
      updateOptions,
      refresh,
      // Flags
      isPending,
      hasBeenLoadedAtLeastOnce
    };
  }
});
